import { cookieStorage, makePersisted } from '@solid-primitives/storage';
import { useSearchParams } from '@solidjs/router';
import { createContext, useContext } from 'solid-js';
import { createStore, produce } from 'solid-js/store';
import { isServer } from 'solid-js/web';
import type { ParentProps } from 'solid-js';

type StoreData = Record<string, { value: string; expires: number }>;
type Store = ReturnType<typeof createStore<StoreData>>;
const Persistence = createContext<Store>();

const persistenceConfig = {
	promoCode: /* ms */ 1000 * /* s */ 60 * /* m */ 60 * /* h */ 24 /*d*/ * 5,

	// Google Merchant
	rwg_token: /* ms */ 1000 * /* s */ 60 * /* m */ 60 * /* h */ 24 /*d*/ * 30,
};

const now = Date.now();

export function PersistenceProvider(props: ParentProps) {
	const [search] = useSearchParams();

	const [store, setStore] = makePersisted(createStore<StoreData>({}), {
		name: 'tgp',
		storage: cookieStorage.withOptions({
			expires: new Date(+now + 1000 * 60 * 60 * 24 * 30),
			sameSite: 'Strict',
			secure: true,
			path: '/',
		}),
		serialize(data) {
			return btoa(JSON.stringify(data));
		},
		deserialize(data) {
			let parsed: StoreData = {};
			try {
				parsed = JSON.parse(atob(data));
			} catch {
				// no-op
			}

			return Object.entries(parsed).reduce((memo, [key, val]) => {
				if (typeof val === 'string') {
					memo[key] = { value: val, expires: now + 1000 * 60 * 60 * 24 };
					return memo;
				}
				if (val.expires > now) {
					memo[key] = val;
				}
				return memo;
			}, {} as StoreData);
		},
	});

	// The cookie doesn't get set if you pass this as the initial value
	// However, we don't want to re-run this on the client, as that creates a flash of re-render
	if (isServer) {
		setStore(
			produce((s) => {
				for (const [key, ttl] of Object.entries(persistenceConfig)) {
					const value = search[key];
					if (value) {
						if (s[key]?.value === value) {
							continue;
						}
						const expires = ttl + now;
						if (Array.isArray(value)) {
							if (value.length) {
								s[key] = { value: value[value.length - 1]!, expires: expires };
							}
						} else {
							s[key] = { value, expires: expires };
						}
					}
				}
			}),
		);
	}

	return <Persistence.Provider value={[store, setStore]}>{props.children}</Persistence.Provider>;
}

export function usePersisted() {
	const ctx = useContext(Persistence);
	if (!ctx) {
		throw new Error('Persistence store not initialized');
	}

	return ctx;
}
